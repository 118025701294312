import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const tabEvent = new KeyboardEvent('keydown', {
  bubbles: true,
  cancelable: true,
  code: 'Tab',
  key: 'Tab',
  charKode: 9,
  keyCode: 9,
  shiftKey: false,
  view: window,
});
export default function DateEditor({ value, onCommit }) {
  const input =  useRef();
  useEffect(() => {
    input.current.focus();
  }, []);
  return <input
    ref={input} 
    className="date-picker"
    type="date"
    value={moment(value).format('YYYY-MM-DD')}
    onChange={e => onCommit(e.target.value, tabEvent)}
  />
}

DateEditor.propTypes = {
  // cell: PropTypes.object,
  // onChange: PropTypes.func,
  onCommit: PropTypes.func,
  value: PropTypes.string,
}
