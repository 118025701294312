import React, { useCallback } from 'react'
import PropTypes from 'prop-types';
import axios from 'axios';
import Spreadsheet from 'components/Spreadsheet/Spreadsheet';

import { boolOptions, strategyOptions } from 'utils';

export default function StacksSpreadsheet({ data, editable }) {
  
  const onSave = useCallback(
    async (change) => {
      const body = {
        value: change.value,
        server_id: change.cell.server_id,
        field: change.cell.field,
        data_type: change.cell.type,
      };
      await axios
      .get('/client_servers/update_server_attribute_from_cell', {
        params: { body },
      })
    },
    [],
  );
  const sheetColumns = data.map((item) => ({
    name: item.hostname,
    width: 200,
    key: item.id,
  }));
  return (
    <div className="stackes-spreadsheet">
      <Spreadsheet data={data} editable={editable} columns={columnDefs} sheetColumns={sheetColumns} onSave={onSave} />
    </div>
  )
}

StacksSpreadsheet.propTypes = {
  data: PropTypes.array,
  editable: PropTypes.bool,
};


const columnDefs = [
  {
    field: 'hostname',
    headerName: 'Hostname',
    type: 'string',
    width: 200,
  },
  {
    field: 'host_ip',
    headerName: 'Host IP',
    type: 'string',
    regex: 'IP_REGEX',
    width: 200,
  },
  {
    field: 'migration_strategy',
    headerName: 'Migration Strategy',
    type: 'string',
    options: strategyOptions,
    dataEditor: 'StrategySelectEditor',
    valueViewer: 'StrategyViewer',
    width: 200,
  },
  {
    field: 'total_storage',
    headerName: 'Total Storage',
    dataEditor: 'NumberEditor',
    type: 'decimal',
    decimal: 2,
    signed:false
  },
  {
    field: 'shared_storage',
    headerName: 'Shared Storage',
    type: 'boolean',
    options: boolOptions,
    dataEditor: 'SelectEditor',
    valueViewer: 'BooleanViewer',
  },
  {
    field: 'peak_iops',
    headerName: 'Peak IOPs',
    dataEditor: 'NumberEditor',
    type: 'integer',
    signed:false
  },
  {
    field: 'avg_iops',
    headerName: 'Avg IOPs',
    dataEditor: 'NumberEditor',
    type: 'decimal',
    signed:false
  },
  {
    field: 'cpu_cores',
    headerName: 'CPU Cores',
    dataEditor: 'NumberEditor',
    type: 'integer',
    signed:false
  },
  {
    field: 'cpu_speed_ghz',
    headerName: 'CPU speed(GHz)',
    type: 'string'
  },
  {
    field: 'peak_cpu_load_pct',
    headerName: 'Peak CPU load(%)',
    dataEditor: 'NumberEditor',
    type: 'decimal',
    signed:false
  },
  {
    field: 'avg_cpu_load_pct',
    headerName: 'Avg. CPU load(%)',
    dataEditor: 'NumberEditor',
    signed:false,
    type: 'decimal',
  },
  {
    field: 'ram_gb',
    headerName: 'Ram GB',
    dataEditor: 'NumberEditor',
    signed:false,
    type: 'decimal',
    decimal: 2,
  },
  {
    field: 'avg_ram_load_pct',
    headerName: 'Avg. RAM Load(%)',
    dataEditor: 'NumberEditor',
    type: 'decimal',
    signed:false
  },
  {
    field: 'peak_ram_load_pct',
    headerName: 'Peak RAM Load(%)',
    dataEditor: 'NumberEditor',
    type: 'decimal',
    signed:false
  },
  {
    field: 'os_type',
    headerName: 'Operating System',
    type: 'string',
    width: 200,
  },
  {
    field: 'os_version',
    headerName: 'OS Version',
    hide: true,
    type: 'string',
    width: 200,
  },
  {
    field: 'web_tier',
    headerName: 'Web Tier',
    type: 'boolean',
    options: boolOptions,
    dataEditor: 'SelectEditor',
    valueViewer: 'BooleanViewer',
  },
  {
    field: 'app_tier',
    headerName: 'App Tier',
    type: 'boolean',
    options: boolOptions,
    dataEditor: 'SelectEditor',
    valueViewer: 'BooleanViewer',
  },
  {
    field: 'database_tier',
    headerName: 'Database Tier',
    type: 'boolean',
    options: boolOptions,
    dataEditor: 'SelectEditor',
    valueViewer: 'BooleanViewer',
  },
  {
    field: 'database_engine',
    headerName: 'Database Engine',
    type: 'string',
    width: 200,
  },
  {
    field: 'database_engine_version',
    headerName: 'Database Engine V.',
    type: 'string',
    width: 200,
  },
  {
    field: 'database_instance_name',
    headerName: 'Database Instance',
    type: 'string',
    width: 200,
  },
  {
    field: 'database_lead_email',
    headerName: 'Database Lead Email',
    type: 'string',
    width: 200,
  },
  {
    field: 'database_lead_name',
    headerName: 'Database Lead Name',
    type: 'string',
    width: 200,
  },
  {
    field: 'database_lead_phone',
    headerName: 'Database lead phone',
    type: 'string',
    width: 200,
  },
  {
    field: 'shared_service',
    headerName: 'Shared Service',
    type: 'boolean',
    options: boolOptions,
    dataEditor: 'SelectEditor',
    valueViewer: 'BooleanViewer',
  },
  {
    field: 'clustered_service',
    headerName: 'Clustered Service',
    type: 'boolean',
    options: boolOptions,
    dataEditor: 'SelectEditor',
    valueViewer: 'BooleanViewer',
  },
  {
    field: 'multiple_stacks',
    headerName: 'Multiple Stacks',
    type: 'boolean',
    options: boolOptions,
    dataEditor: 'SelectEditor',
    valueViewer: 'BooleanViewer',
  },
  {
    field: 'publicly_accessible',
    headerName: 'Publicly Accessible',
    type: 'boolean',
    options: boolOptions,
    dataEditor: 'SelectEditor',
    valueViewer: 'BooleanViewer',
  },
  {
    field: 'load_balancer_required',
    headerName: 'Load Balancer Required',
    type: 'boolean',
    options: boolOptions,
    dataEditor: 'SelectEditor',
    valueViewer: 'BooleanViewer',
  },
  {
    field: 'backup_type',
    headerName: 'Backup Type',
    type: 'string',
  },
  {
    field: 'server_type',
    headerName: 'Server Type',
    type: 'string',
  },
  {
    field: 'server_support_lead_name',
    headerName: 'Server Support',
    type: 'string',
  },
  {
    field: 'server_support_lead_email',
    headerName: 'Server Support Email',
    type: 'string',
  },
  {
    field: 'server_support_lead_phone',
    headerName: 'Server Support Phone',
    type: 'string',
  },
];
