import React from 'react';

import Select from 'react-select';
import { regulatoryRequirements } from 'utils';

const tabEvent = new KeyboardEvent('keydown', {
  bubbles: true,
  cancelable: true,
  code: 'Tab',
  key: 'Tab',
  charKode: 9,
  keyCode: 9,
  shiftKey: false,
  view: window,
});

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]

// const MultiSelectEditor = () => (
//   <Select options={options} />
// )

export default ({cell, value, onChange, onCommit}) => (
  <Select
    onChange={selectedValues => {
      onChange(selectedValues);
    }}
    onKeyDown={(e)=>{
      if (e.key === 'Tab') {
        onCommit(value, tabEvent)
      }
    }}
    value={value}
    isMulti
    options={regulatoryRequirements}
  />
);
// import React from 'react';
//
// import Select from 'react-select';
// // import { colourOptions, groupedOptions } from '../data';
//
// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' }
// ]
// const groupStyles = {
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'space-between',
// };
// const groupBadgeStyles = {
//   backgroundColor: '#EBECF0',
//   borderRadius: '2em',
//   color: '#172B4D',
//   display: 'inline-block',
//   fontSize: 12,
//   fontWeight: 'normal',
//   lineHeight: '1',
//   minWidth: 1,
//   padding: '0.16666666666667em 0.5em',
//   textAlign: 'center',
// };
//
// const formatGroupLabel = data => (
//   <div style={groupStyles}>
//     <span>{data.label}</span>
//     <span style={groupBadgeStyles}>{data.options.length}</span>
//   </div>
// );
//
// export default () => (
//   <Select
//     defaultValue={options[1]}
//     options={options}
//     // formatGroupLabel={formatGroupLabel}
//   />
// );
