import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import DataGrid from 'components/DataGrid';
import Tabs from 'components/Tabs';

const tabs = [
  { label: 'Overall', value: 0 },
  { label: 'Your Servers', value: 1 },
  { label: 'Pending Review', value: 2 },
]

const ServersTable = ({ isApplicationOwner }) => {
  const [gridRef, setGridRef] = useState(React.createRef());
  const [activeTab, setActiveTab] = useState(0);
  const getParams = useCallback(() => {
    setGridRef(React.createRef());
    return `type=${activeTab}`
  }, [activeTab]);

  const columnDefs = [
    {
      field: 'hostname',
      headerName: 'Hostname',
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        return `<a rel="" href="/client_servers/${data.id}">${data.hostname}</a>`;
      },
      type: ['textColumn'],
    },
    {
      field: 'host_ip',
      headerName: 'Host IP',
      sortable: true,
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        return `<a rel="" href="/client_servers/${data.id}">${data.host_ip}</a>`;
      },
      filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        suppressAndOrCondition: true,
      },
    },
    {
      field: 'application_name',
      headerName: 'Applications',
    },
    {
      field: 'migration_strategy',
      headerName: 'Migration Strategy',
    },
    {
      field: 'os_type',
      headerName: 'Operating System',
    },
    {
      field: 'wave_number_text',
      headerName: 'Wave #',
      sortable: true
    },
    {
      field: 'os_version',
      headerName: 'OS Version',
      hide: true,
    },
    {
      field: 'web_tier',
      headerName: 'Web Tier',
      type: ['booleanColumn'],
      hide: true,
    },
    {
      field: 'app_tier',
      headerName: 'App Tier',
      type: ['booleanColumn'],
      hide: true,
    },
    {
      field: 'database_tier',
      headerName: 'Database Tier',
      type: ['booleanColumn'],
      hide: true,
    },
    {
      field: 'updated_at',
      headerName: 'Last Updated',
      type: ['dateColumn'],
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { value } = params;
        return value ? moment(value).fromNow() : 'NA';
      },
      hide: true,
    },
    {
      field: 'migration_time',
      headerName: 'Scehduled Migration Time',
      type: ['dateColumn'],
      hide: true,
    },
    {
      field: 'target_aws_account',
      headerName: 'AWS Account',
      hide: true,
    },
    {
      field: 'vpc_id',
      headerName: 'VPC ID',
      hide: true,
    },
    {
      field: 'subnet_id',
      headerName: 'Subnet ID',
      hide: true,
    },
  ];

  return (
    <div>
      {isApplicationOwner && (
        <>
          <Tabs tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} />
          <hr />
        </>
      )}
      <DataGrid
        key={`key-${activeTab}`}
        tableId="client_servers"
        columnDefs={columnDefs}
        apiUrl="/client_servers/get_servers_json"
        gridRef={gridRef}
        getParams={getParams}
        deleteInfo={{
          deleteApiUrl: '/client_servers/batch_delete',
          deleteIdKey: 'server_ids',
          deleteWarning: 'WARNING: This will permanently remove the selected servers. Are you sure?',
        }}
      />
    </div>
  );
}

ServersTable.propTypes = {
  isApplicationOwner: PropTypes.any,
};
ServersTable.defaultProps = {
  isApplicationOwner: false,
};

export default ServersTable;
