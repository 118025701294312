import React from 'react';
import PropTypes from 'prop-types';
import AccordionItem from './AccordionItem';
import styles from './styles.module.scss';

class Accordion extends React.Component {
  state = { opened: 0 };

  toggleOpen = (index) => {
    this.setState(prev => ({
      opened: prev.opened === index ? undefined : index
    }))
  };
  render () {
    const { data } = this.props;
    const { opened } = this.state;
    return (
      <div className={styles['accordion-wrapper']}>
        <ul className="accordion-list">
          {data.map((data, index) => {
            return (
              <li className="accordion-list__item" key={data.title}>
                <AccordionItem {...data} index={index} opened={index === opened} onClick={this.toggleOpen} />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

Accordion.propTypes = {
  data: PropTypes.array
}

export default Accordion;
