import React from 'react';
import PropTypes from 'prop-types';

const SORTING = ['', 'asc', 'desc'];

export default class CustomHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      sort: '',
      sortIndex: 0,
      ascSort: false,
      descSort: false,
      noSort: true,
      isFilterActive: false,
    };
    this.colFilterInstance = props.column.gridApi.getFilterInstance(
      props.column.colId
    );

    this.menuButton = React.createRef();
    props.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
    props.column.addEventListener('filterChanged', this.onFilterChanged);
  }

  componentDidMount() {
    this.onSortChanged();
  }

  onFilterChanged = () => {
    const isFilterActive = this.colFilterInstance.isFilterActive();
    this.setState({ isFilterActive });
  };

  onMenuClicked = (e) => {
    e.stopPropagation();
    this.props.showColumnMenu(this.menuButton.current);
  };

  onSortChanged() {
    const ascSort = this.props.column.isSortAscending();
    const descSort = this.props.column.isSortDescending();
    this.setState(prev => ({
      ascSort,
      descSort,
      noSort: !ascSort && !descSort,
      sortIndex: !ascSort && !descSort ? 0 : prev.sortIndex
    }));
  }

  onSortRequested = (order, event) => {
    if(!this.props.enableSorting) return;
    event.stopPropagation();
    const { sortIndex } = this.state;
    const newSortIndex = (sortIndex + 1) % 3;
    const sort = SORTING[newSortIndex];
    this.props.setSort(sort, event.shiftKey);
    this.setState({ sort, sortIndex: newSortIndex });
  };

  resetColumn = (e) => {
    e.stopPropagation();
    const { ascSort, descSort} = this.state;
    this.colFilterInstance.setModel(null);
    this.props.column.gridApi.onFilterChanged();
    if (ascSort || descSort) {
      this.props.column.gridApi.setSortModel(null);
      this.props.column.gridApi.onSortChanged();
    }
    this.setState({ sort: '', sortIndex: 0 });
  };

  render() {
    const { isFilterActive, ascSort, descSort } = this.state;
    const { enableMenu } = this.props;

    let sort = null;
    if (this.props.enableSorting) {
      sort = (
        <>
          {ascSort && (
            <i
              className={'fa fa-arrow-down'}
              onClick={(e) => this.onSortRequested('desc', e)}
              onTouchEnd={(e) => this.onSortRequested('desc', e)}
            />
          )}
          {descSort && (
            <i
              className={'fa fa-arrow-up'}
              onClick={(e) => this.onSortRequested('asc', e)}
              onTouchEnd={(e) => this.onSortRequested('asc', e)}
            />
          )}
        </>
      );
    }

    return (
      <div
        className="custom-react-col-header"
        onClick={(e) => this.onSortRequested('', e)}
        onTouchEnd={(e) => this.onSortRequested('', e)}
      >
        <span>
          {enableMenu && (
            <i
              className="fa fa-bars"
              onClick={this.onMenuClicked}
              ref={this.menuButton}
            ></i>
          )}
          <span
            className="customHeaderLabel"
            onClick={(e) => this.onSortRequested('', e)}
          >
            {this.props.displayName}
          </span>
          {isFilterActive && <i className="fa fa-filter" />}
          {sort}
        </span>
        <span>
    
          {(isFilterActive || (ascSort || descSort)) && <i className="fa fa-times" onClick={this.resetColumn} />}
        </span>
      </div>
    );
  }
}

CustomHeader.propTypes = {
  column: PropTypes.shape({
    addEventListener: PropTypes.func,
    colId: PropTypes.string,
    gridApi: PropTypes.shape({
      getFilterInstance: PropTypes.func,
      onFilterChanged: PropTypes.func,
      onSortChanged: PropTypes.func,
      setSortModel: PropTypes.func,
    }),
    isSortAscending: PropTypes.func,
    isSortDescending: PropTypes.func,
  }),
  displayName: PropTypes.string,
  enableMenu: PropTypes.bool,
  enableSorting: PropTypes.bool,
  setSort: PropTypes.func,
  showColumnMenu: PropTypes.func,
}
