import React, { useRef, useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import GridFooter from './GridFooter';
import GridToolbar from './GridToolbar';
import CustomHeader from './CustomHeader';
import ColumnsPanel from './GridToolbar/ColumnsPanel';
import ActionsPanel from './GridToolbar/ActionsPanel';
import { PAGE_SIZE, DATE_FORMAT } from 'constants/index';
import { dateComparator, checkboxSelection, TableContext, getEnumColumnParams } from 'utils';
import loader from 'assets/images/loader.gif';
import emptyBox from 'assets/images/empty-box.svg';
import styles from './styles.module.scss';

const DataGrid = ({
  tableId,
  className,
  columnDefs,
  apiUrl,
  pageSize = 100,
  rowSelection,
  rowDeselection,
  rowModelType,
  getRowNodeId,
  onRowSelected,
  defaultColDef,
  columnTypes,
  frameworkComponents,
  overlayNoRowsTemplate,
  overlayLoadingTemplate,
  suppressLoadingOverlay,
  suppressNoRowsOverlay,
  deleteInfo,
  defaultSort,
  context: contextProp,
  gridRef,
  getParams,
  panels,
}) => {
  if (!tableId) {
    throw Error('tableId field is required');
  }
  const [gridReady, setGridReady] = useState(false);
  const context = useRef(contextProp);
  const gridApi = useRef();
  const columnApi = useRef();

  /* ----------------- columnSelection ------------------ */
  const [columns, setColumns] = useState([]);
  const [columnSelection, setColumnSelection] = useState({});
  useEffect(() => {
    let columns = columnDefs;
    let selection = {};
    try {
      selection = JSON.parse(
        localStorage.getItem(`columnSelection:${tableId}`) || '{}'
      );
    } catch (error) {
      selection = {};
    }
    if (Object.keys(selection).length > 0) {
      columns = columnDefs.map((col) => {
        if (selection[col.field] !== undefined) {
          col.hide = !selection[col.field];
        }
        return col;
      });
      localStorage.setItem(
        `columnSelection:${tableId}`,
        JSON.stringify(selection)
      );
    } else {
      columnDefs.forEach((col) => {
        selection[col.field] = !(col.hide || false);
      });
    }
    columns = columns.map(col => {
      if (!col.minWidth) {
        col.minWidth = (col.headerName || col.field).length * 8 + 112;
      }
      return col;
    });
    setColumnSelection(selection);
    setColumns(columns);
  }, [columnDefs, tableId]);
  /* ----------------------------------------------------- */

  /* --------- datasource and data fetch logic ----------- */
  const dataSource = useMemo(() => {
    return {
      rowCount: null,
      getRows: async (params) => {
        var { data, total } = await getData(params, pageSize);
        params.successCallback(data, total);
      },
    };
  }, [getData, pageSize]);

  const getData = useCallback(
    async (request, pageSize) => {
      if (!gridApi.current) return { total: 0, data: [] };
      const { endRow, filterModel, sortModel } = request;
      const page = pageSize ? endRow / pageSize: 1;
      let url = `${apiUrl}?page=${page}&per_page=${pageSize}`;
      url += `&filters=${encodeURIComponent(JSON.stringify(filterModel))}`;
      if (sortModel.length > 0) {
        const [{ colId, sort }] = sortModel;
        url += `&sort=${colId} ${sort}`;
      }
      const customParams = getParams();
      if (customParams) {
        url += `&${customParams}`;
      }
      const urlParams = new URLSearchParams(window.location.search);
      const app_id = urlParams.get('app_id');
      if (app_id) {
        url += `&app_id=${app_id}`;
      }
      gridApi.current.showLoadingOverlay();
      const { total, data, context: userInfo } = await fetch(url)
        .then((response) => response.json())
        .catch((error) => {
          // eslint-disable-next-line
          console.log("error getData", error);
          return { total: 0, data: [] };
        })
        .finally(() => {
          gridApi.current.hideOverlay();
        });
      if (total === 0) {
        gridApi.current.showNoRowsOverlay();
      }
      if (rowModelType !== 'infinite') {
        gridApi.current.setRowData(data);
      }
      // This is intentional and changes will be reflected inside grid ccontext object
      context.current = Object.assign(context.current, userInfo);      
      return { total, data , context };
    },
    [apiUrl, rowModelType]
  );
  /* ----------------------------------------------------- */



  /* --------- set gridApi and columnApi refs ----------- */
  const onGridReady = useCallback(async (params) => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;
    if (gridRef) {
      gridRef.current = { gridApi: params.api, columnApi: params.columnApi };
    }
    if (defaultSort){
      gridApi.current.setSortModel([defaultSort]);
    }
    if (rowModelType !== 'infinite') {
      getData({
        endRow: 0,
        filterModel: {},
        sortModel: []
      }, 0);
    }
    setGridReady(true);
  }, [defaultSort, getData, rowModelType]);
  /* ----------------------------------------------------- */

  /* ------------------- panels ---------------- */
  const onColumnSelection = useCallback(
    (name, checked) => {
      columnApi.current.setColumnVisible(name, checked);
      setColumnSelection((prevSelection) => ({
        ...prevSelection,
        [name]: checked,
      }));
      // If column is hidden, remove filters for that column
      if (!checked) {
        gridApi.current.getFilterInstance(name).setModel(null);
        gridApi.current.onFilterChanged();
        const sortModel = gridApi.current.getSortModel();
        if (sortModel.length > 0) {
          const [{ colId }] = sortModel;
          if (name === colId) {
            gridApi.current.setSortModel(null);
            gridApi.current.onSortChanged();
          }
        }
      }
    },
    []
  );

  useEffect(() => {
    localStorage.setItem(
      `columnSelection:${tableId}`,
      JSON.stringify(columnSelection)
    );
  }, [columnSelection, tableId]);

  const columnsPanel = (
    <ColumnsPanel
      columnSelection={columnSelection}
      columnDefs={columns}
      columnApi={columnApi.current}
      onColumnSelection={onColumnSelection}
    />
  );
  const actionsPanel = (
    <ActionsPanel
      columnSelection={columnSelection}
      columnDefs={columns}
      gridApi={gridApi.current}
      onColumnSelection={onColumnSelection}
    />
  );
  /* ----------------------------------------------- */

  const panelsConfig = {
          Columns: {
              name: 'Columns',
              icon: 'fa-bars',
              content: columnsPanel,
            },
        Actions: {
              name: 'Actions',
              icon: 'fa-pen',
              content: actionsPanel,
            },
          };
  
  return (
    <TableContext.Provider value={{ deleteInfo, context }}>
      <div id={tableId} className={classNames(styles['grid-container'], className)}>
        <div className="table-wrapper">
          <div className="ag-grid ag-theme-alpine">
            <AgGridReact
              columnDefs={columns}
              context={context.current}
              suppressRowClickSelection
              suppressMenuHide
              defaultColDef={{
                sortable: true,
                flex: 1,
                minWidth: 200,
                resizable: true,
                cellClass: 'cell-wrap-text ag-grid-cell',
                autoHeight: true,
                checkboxSelection,
                filter: 'agTextColumnFilter',
                filterParams: {
                  buttons: ['reset', 'apply'],
                  closeOnApply: true,
                  suppressAndOrCondition: true,
                },
                ...defaultColDef,
              }}
              columnTypes={{
                nonEditableColumn: { editable: false },
                dateColumn: {
                  cellRenderer: (params) => {
                    if (!params.data) return '';
                    const { value } = params;
                    return value
                      ? dayjs(value).format(DATE_FORMAT)
                      : 'NA';
                  },
                  filter: 'agDateColumnFilter',
                  filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    suppressAndOrCondition: true,
                    comparator: dateComparator,
                  },
                },
                numberColumn: {
                  filter: 'agNumberColumnFilter',
                  filterParams: {
                    closeOnApply: true,
                    suppressAndOrCondition: true,
                    buttons: ['reset', 'apply'],
                  },
                  cellStyle: { textAlign: 'center' }, 
                },
                textColumn: {
                  filter: 'agTextColumnFilter',
                  filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    suppressAndOrCondition: true,
                  },
                },
                booleanColumn: {
                  ...getEnumColumnParams({
                    true: 'Yes',
                    false: 'No'
                  })
                },
                ...columnTypes,
              }}
              frameworkComponents={{
                agColumnHeader: CustomHeader,
                ...frameworkComponents,
              }}
              onFilterChanged={() => {
                const visibleCount = gridApi.current.getDisplayedRowCount()
                if (visibleCount === 0) {
                  gridApi.current.showNoRowsOverlay();
                } else {
                  gridApi.current.hideOverlay();
                }
              }}
              overlayNoRowsTemplate={overlayNoRowsTemplate}
              overlayLoadingTemplate={overlayLoadingTemplate}
              suppressLoadingOverlay={suppressLoadingOverlay}
              suppressNoRowsOverlay={suppressNoRowsOverlay}
              animateRows
              datasource={dataSource}
              rowSelection={rowSelection}
              rowDeselection={rowDeselection}
              rowModelType={rowModelType}
              paginationPageSize={10}
              cacheBlockSize={pageSize}
              cacheOverflowSize={2}
              maxConcurrentDatasourceRequests={2}
              infiniteInitialRowCount={1}
              getRowNodeId={getRowNodeId}
              onGridReady={onGridReady}
              keepDetailRows
              keepDetailRowsCount
              onRowSelected={onRowSelected}
            />
          </div>
          <GridFooter gridApi={gridApi.current} />
        </div>
        <GridToolbar
          gridReady={gridReady}
          columnSelection={columnSelection}
          gridApi={gridApi.current}
          panels={panels.map(name => {
              return panelsConfig[name];
          })}
        />
      </div>
    </TableContext.Provider>
  );
};

DataGrid.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  columnDefs: PropTypes.array.isRequired,
  columnTypes: PropTypes.object,
  context: PropTypes.any,
  defaultColDef: PropTypes.object,
  defaultSort: PropTypes.shape({
    colId: PropTypes.string.isRequired,
    sort: PropTypes.string.isRequired,
  }),
  deleteInfo: PropTypes.shape({
    deleteApiUrl: PropTypes.string.isRequired,
    deleteIdKey: PropTypes.string.isRequired,
    deleteWarning: PropTypes.string.isRequired,
  }),
  frameworkComponents: PropTypes.object,
  getRowNodeId: PropTypes.func,
  onRowSelected: PropTypes.func,
  getParams: PropTypes.func,
  overlayLoadingTemplate: PropTypes.string,
  overlayNoRowsTemplate: PropTypes.string,
  pageSize: PropTypes.number,
  rowDeselection: PropTypes.bool,
  rowModelType: PropTypes.oneOf(['clientSide', 'infinite', 'viewport', 'serverSide']),
  rowSelection: PropTypes.string,
  suppressLoadingOverlay: PropTypes.bool,
  suppressNoRowsOverlay: PropTypes.bool,
  tableId: PropTypes.string.isRequired,
  gridRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  panels: PropTypes.array,
};

DataGrid.defaultProps = {
  className: '',
  rowSelection: 'multiple',
  rowModelType: 'infinite',
  pageSize: PAGE_SIZE,
  getRowNodeId: (item) => {
    return item.id;
  },
  onRowSelected: () => {},
  getParams: () => '',
  rowDeselection: true,
  overlayNoRowsTemplate: `
  <div>
    <img src="${emptyBox}" width="100" height="100" />
    <p>No data found for selected filter criteria.</p>
  </div>
`,
  overlayLoadingTemplate: `
<div>
  <img src="${loader}" width="50" height="50" />
</div>
`,
  frameworkComponents: {},
  defaultColDef: {},
  columnTypes: {},
  suppressLoadingOverlay: false,
  suppressNoRowsOverlay: false,
  deleteInfo: null,
  context: {},
  gridRef: React.createRef(),
  panels: ['Columns', 'Actions']
};

export default DataGrid;
