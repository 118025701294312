import React from 'react';

import DataGrid from 'components/DataGrid';
import {
  redCountCellRenderer,
  durationCellRenderer,
  dateCellRenderer,
  getEnumColumnParams,
} from 'utils';
import { DATE_FORMAT } from 'constants/index';


const StorageDevicesTable = () => {
  const columnDefs = [
    {
      field: 'device_name',
      headerName: 'Device Name',
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        return `<a rel="" href="/storage_devices/${data.id}">${data.device_name}</a>`;
      },
      type: ['textColumn'],
    },
    {
      field: 'size_gb',
      headerName: 'Size (GB)',
      type: ['numberColumn'],
    },

    {
      field: 'access_protocols',
      headerName: 'Access Protocols',
    },
    {
      field: 'file_system',
      headerName: 'File System',
    },
    {
      field: 'warnings_count',
      headerName: 'Errors',
      type: ['numberColumn'],
      cellRenderer: redCountCellRenderer('text-danger'),
    },
    {
      field: 'updated_at',
      headerName: 'Last Modified',
      type: ['dateColumn'],
      cellRenderer: durationCellRenderer,
    },
    {
      field: 'iops_peak',
      headerName: 'IOPS Peak',
      type: ['numberColumn'],
      hide: true,
    },
    {
      field: 'iops_average',
      headerName: 'IOPS Avg',
      type: ['numberColumn'],
      hide: true,
    },
    {
      field: 'throughput_gb_peak',
      headerName: 'Throughput (Gb)',
      type: ['numberColumn'],
      hide: true,
    },
    {
      field: 'throughput_gb_average',
      headerName: 'Throughput Avg. (Gb)',
      type: ['numberColumn'],
      hide: true,
    },
    {
      field: 'monthly_growth_rate_pct',
      headerName: 'Monthly Growth Rate (%)',
      type: ['numberColumn'],
      hide: true,
    },
    {
      field: 'replicated',
      headerName: 'Replicated',
      ...getEnumColumnParams({
        true: 'Yes',
        false: 'No'
      }),
      hide: true,
    },
    {
      field: 'replication_target',
      headerName: 'Replication Target',
      hide: true,
    },
    {
      field: 'created_at',
      headerName: 'Date Added',
      type: ['dateColumn'],
      cellRenderer: dateCellRenderer(DATE_FORMAT),
      hide: true,
    },
  ];

  return (
    <DataGrid
      tableId="storage_devices"
      columnDefs={columnDefs}
      apiUrl="/storage_devices/get_storage_devices_json"
      defaultSort={{ colId: 'created_at', sort: 'desc' }}
    />
  );
};

export default StorageDevicesTable;
