import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function CellRenderer({ cell, row, col, editing, updated, className, /*columns, selected, editing, style, ...rest,*/ children,   ...rest }) {
  delete rest.attributesRenderer;
  // hey, how about some custom attributes on our cell?
  const attributes = cell.attributes || {};
  // ignore default style handed to us by the component and roll our own
  attributes.style = { width: 200 }; //columns[col].width };
  if (col === 0) {
    attributes.title = cell.label;
  }
  const titleProp = {};
  if (cell.title) {
    titleProp.title = cell.title;
  }
  return (
    <td
      {...rest}
      {...attributes}
      {...titleProp}
      className={classNames(className, { invalid: cell.invalid })}
      id={`cell-${row}-${col}`}
    >
      <div className="cell-content">{children}</div>
    </td>
  );
}

CellRenderer.propTypes = {
  children: PropTypes.any,
  cell: PropTypes.object,
  row: PropTypes.number,
  col: PropTypes.number,
  // columns: PropTypes.array,
  editing: PropTypes.bool,
  updated: PropTypes.bool,
}
