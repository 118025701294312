import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';


import TextEditor from './TextEditor';
import CellViewer from './CellViewer';
import CellRenderer from './CellRenderer';
import SheetRenderer from './SheetRenderer';

import { getSpreadsheetData } from 'utils';
import loader from 'assets/images/loader.gif';
import styles from './styles.module.scss';



/* global Notifier */

export default function Spreadsheet({ label, data, columns, sheetColumns, editable, onSave, hideNotifications, cellAttributes, className, headerColWidth, colWidth, style }) {
  const [loading, setLoading] = useState(false);
  const [gridData, setGridData] = useState(
    getSpreadsheetData({ data, columns, editable, cellAttributes, headerColWidth, colWidth  })
  );

  const updateData = useCallback(async (change, data) => {
    
    const { row, col, cell } = change;
    setLoading(true);
    try {
      
      await onSave(change);

      if (!hideNotifications) Notifier.success('Saved changes', '', 1000);
      if (cell.invalid) {
        const grid = data.map((row) => [...row]);
        grid[row][col] = { ...grid[row][col], invalid: false, title: '' };
        setGridData(grid);
      }

    } catch (error) {
      if (!hideNotifications) Notifier.error(error.message || 'Unable to save change', '', 3000);
      const grid = data.map((row) => [...row]);
      grid[row][col] = { ...grid[row][col], invalid: true, title: error.message || 'Unable to save change'};
      setGridData(grid);
    } finally {
      setLoading(false)
    }
  }, [onSave, hideNotifications])

  return (
    <div className={`${styles.spreadsheet} spread-sheet ${className}`}>
      {loading && (
        <div className="loader">
          <img src={loader} width="50" height="50" />
        </div>
      )}
      <ReactDataSheet
        data={gridData}
        valueViewer={CellViewer}
        sheetRenderer={(props) => {
          return (
            <SheetRenderer
              {...props}
              label={label}
              columns={sheetColumns}
              headerColWidth={headerColWidth}
              style={style}
            />
          );
        }}
        valueRenderer={(cell) => cell.value}
        onCellsChanged={(changes) => {
          const change = changes[0];
          const grid = gridData.map((row) => [...row]);
          changes.forEach(({ row, col, value }) => {
            grid[row][col] = { ...grid[row][col], value };
          });
          setGridData(grid);
          updateData(change, grid);
        }}
        cellRenderer={CellRenderer}
        dataEditor={TextEditor}
      />

    </div>
  );
}

Spreadsheet.propTypes = {
  data: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  hideNotifications: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    headerName: PropTypes.string
  })).isRequired,
  sheetColumns: PropTypes.array,
  className: PropTypes.string,
  label: PropTypes.string,
  cellAttributes: PropTypes.object,
  style: PropTypes.object,
  headerColWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
Spreadsheet.defaultProps = {
  editable: true,
  className: '',
  label: 'Attributes',
  hideNotifications: false,
  cellAttributes: {},
  headerColWidth: 200,
  colWidth: 200,
  style: {},
};

