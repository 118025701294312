import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

export default function TextEditor({ value, onCommit, onChange, className, cell }) {
  const { regex } = cell;
  const input =  useRef();
  const [isValid, setIsValid] = useState(true);
  useEffect(() => {
    input.current.focus();
  }, []);

  const onInput = useCallback((evt) => {
    let newValue = evt.target.value;
    if (regex && !regex.test(newValue)) {
      // newValue = value;
      setIsValid(false);
      input.current.parentNode.parentNode.classList.add('invalid');
    } else {
      setIsValid(true)
      input.current.parentNode.parentNode.classList.remove('invalid');
    }
    onChange(newValue);
  }, [onChange, regex]);

  return <input ref={input} type="text" className={`data-editor ${className} ${isValid ? 'valid' : 'invalid'}`} onChange={onInput} value={value} onKeyDown={e => {
    if (e.key === 'Tab') onCommit(value, e);
  }} />
}

TextEditor.propTypes = {
  cell: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onCommit: PropTypes.func,
  value: PropTypes.string,
}
