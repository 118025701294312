// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__storagedeviceSpreadsheet___39YhR .spread-sheet .data-grid-container .table-container{width:100% !important}.styles-module__storagedeviceSpreadsheet___39YhR .spread-sheet .data-grid-container .table-container .data-grid{width:100% !important;max-height:500px !important}.styles-module__storagedeviceSpreadsheet___39YhR .spread-sheet .data-grid-container .table-container .data-grid th.read-only,.styles-module__storagedeviceSpreadsheet___39YhR .spread-sheet .data-grid-container .table-container .data-grid .cell.read-only{background-color:rgba(195, 213, 229, .4784313725)}", "",{"version":3,"sources":["/app/app/javascript/components/Spreadsheet/Tables/StorageDeviceSpreadsheet/styles.module.scss"],"names":[],"mappings":"AAOQ,qGACE,qBAAA,CACA,gHACE,qBAAA,CACA,2BAAA,CAGE,6PACE,iDAAA","file":"styles.module.scss","sourcesContent":["$padding-left: 7px;\n$cell-height: 30px;\n\n.storagedeviceSpreadsheet {\n  :global {\n    .spread-sheet {\n      .data-grid-container {\n        .table-container {\n          width: 100% !important;\n          .data-grid {\n            width: 100% !important;\n            max-height: 500px !important;\n            th,\n            .cell {\n              &.read-only {\n                background-color: #c3d5e57a;\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"storagedeviceSpreadsheet": "styles-module__storagedeviceSpreadsheet___39YhR"
};
module.exports = exports;
