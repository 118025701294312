import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types';

const tabEvent = new KeyboardEvent('keydown', {
  bubbles: true,
  cancelable: true,
  code: 'Tab',
  key: 'Tab',
  charKode: 9,
  keyCode: 9,
  shiftKey: false,
  view: window,
});

export default function SelectEditor({ cell, value, onCommit }) {
  const input =  useRef();
  useEffect(() => {
    input.current.focus();
  }, []);
  return (
    <select ref={input} className="form-control" value={value} onChange={e => {
      onCommit(e.target.value === 'true', tabEvent);
    }}>
      <option key="null" value=""></option>
      {cell.options.map(op => <option key={op.value} value={op.value}>{op.label}</option>)}
    </select>
  );
}

SelectEditor.propTypes = {
  cell: PropTypes.shape({
    options: PropTypes.array,
  }),
  onChange: PropTypes.func,
  onCommit: PropTypes.func,
  value: PropTypes.any,
}
