// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__formCheck___3Bnx1{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.styles-module__formCheck___3Bnx1 .styles-module__label___1u484{margin-bottom:0}", "",{"version":3,"sources":["/app/app/javascript/components/Checkbox/styles.module.scss"],"names":[],"mappings":"AAAA,kCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,gEACE,eAAA","file":"styles.module.scss","sourcesContent":[".formCheck {\n  display: flex;\n  align-items: center;\n  .label {\n    margin-bottom: 0;\n  }\n}"]}]);
// Exports
exports.locals = {
	"formCheck": "styles-module__formCheck___3Bnx1",
	"label": "styles-module__label___1u484"
};
module.exports = exports;
