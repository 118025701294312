import React from 'react';

import DataGrid from '../DataGrid';
import { durationCellRenderer, dateCellRenderer, redCountCellRenderer } from 'utils';
import { DATE_FORMAT } from 'constants/index';

const ApplicationsTable = () => {
  const columnDefs = [
    {
      field: 'application_name',
      headerName: 'Application',
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        return `<a rel="" href="/client_applications/${data.id}">${data.application_name}</a>`;
      },
      type: ['textColumn'],
    },
    {
      field: 'application_owner',
      headerName: 'Owner',
    },
    {
      field: 'server_count',
      headerName: 'Servers',
      type: ['numberColumn'],
    },
    {
      field: 'real_priority_score',
      headerName: 'Priority Score',
      type: ['numberColumn'],
    },
    {
      field: 'options',
      headerName: 'Options',
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        return `<a rel="" href="/client_servers?app_id=${data.id}">Show Servers</a>`;
      },
    },
    {
      field: 'application_owner_email',
      headerName: 'Owner Email',
      hide: true,
    },
    {
      field: 'technology_stack',
      headerName: 'Technology  Stack',
      hide: true,
    },
    {
      field: 'acronym',
      headerName: 'Acronym',
      hide: true,
    },
    {
      field: 'created_at',
      headerName: 'Date Added',
      type: ['dateColumn'],
      cellRenderer: dateCellRenderer(DATE_FORMAT),
      hide: true,
    },
    {
      field: 'updated_at',
      headerName: 'Last Modified',
      type: ['dateColumn'],
      cellRenderer: durationCellRenderer,
      hide: true,
    },
    {
      field: 'warnings_count',
      headerName: 'Warnings',
      type: ['numberColumn'],
      cellRenderer: redCountCellRenderer('text-danger'),
      hide: true,
    },
    {
      field: 'servers_with_warnings_count',
      headerName: 'Servers with Warnings',
      type: ['numberColumn'],
      cellRenderer: redCountCellRenderer('text-danger'),
      hide: true,
    },
  ];

  return (
    <DataGrid
      tableId="client_servers"
      columnDefs={columnDefs}
      apiUrl="/client_applications/get_applications_json"
      defaultSort={{ colId: 'created_at', sort: 'desc' }}
      deleteInfo={{
        deleteApiUrl: '/client_applications/batch_delete',
        deleteIdKey: 'application_ids',
        deleteWarning: 'WARNING: If there are servers serving other applications, please unassign them. Are you sure you want to delete this application? This will also delete all associated servers that do not serve any other apps.',
      }}
    />
  );
}

export default ApplicationsTable;
