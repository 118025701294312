import React, { useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Accordion from 'components/Accordion';
import Checkbox from 'components/Checkbox';
import DeleteRows from 'components/DataGrid/GridToolbar/DeleteRows';

import styles from './styles.module.scss';
import { TableContext } from 'utils';
import csvIcon from 'assets/images/csv.svg';
import useGridSelectedRows from 'hooks/useGridSelectedRows';
import useAvailableGridRowCount from 'hooks/useAvailableGridRowCount';

const ActionsPanel = ({ gridApi }) => {
  const [fileName, setFileName] = useState('');
  const { availableRows } = useAvailableGridRowCount(gridApi);
  const { count: selectedRows } = useGridSelectedRows(gridApi);
  const [exportSelection, setExportSelection] = useState({
    allColumns: false,
    onlySelected: false,
    columnKeys: null,
    skipHeader: false,
  });
  const { deleteInfo } = useContext(TableContext)
  

  const selectionConfig = [
    {
      id: 'onlySelected',
      label: `Available ${availableRows} rows`,
    },
    {
      id: 'allColumns',
      label: 'All columns data',
    },
    {
      id: 'skipHeader',
      label: 'Exclude Header Row',
    },
  ];

  const setValue = useCallback((name, checked) => {
    setExportSelection((v) => ({ ...v, [name]: checked }));
  }, []);
  
  const downloadCsv = useCallback(() => {
    
    gridApi.exportDataAsCsv({ ...exportSelection, onlySelected: !exportSelection.onlySelected, fileName });
  }, [gridApi, exportSelection, fileName]);

  

  if (!gridApi) return null;
  let data = [
    {
      title: 'Export Selected Rows to CSV',
      opened: true,
      content: (
        <div>
          {selectionConfig.map(({ id, label }) => (
            <Checkbox
              key={id}
              id={id}
              label={label}
              checked={exportSelection[id]}
              onChange={(name, checked) => setValue(name, checked)}
            />
          ))}

          <div className="form-group">
            <label htmlFor="exportFileName">Export File Name</label>
            <input
              type="text"
              className={classNames('form-control', styles.fileNameInput)}
              onChange={(e) => setFileName(e.target.value)}
              value={fileName}
              id="exportFileName"
              placeholder="export.csv"
            />
          </div>
          <button
            className={classNames('btn btn-primary', styles.exportBtn)}
            type="button"
            onClick={downloadCsv}
            disabled={!exportSelection.onlySelected && selectedRows === 0}
          >
            Export {exportSelection.onlySelected ? availableRows : selectedRows} Rows
            <img
              className="export-icon csv"
              width="40"
              src={csvIcon}
              alt="Export to csv"
            />
          </button>
        </div>
      ),
    },
  ];
  if (deleteInfo) {
    data = [...data, {
      title: 'Delete Rows',
      content: (
        <DeleteRows gridApi={gridApi} />
      ),
    },]
  }
  return (
    <div
      className={classNames(
        'grid-panel-content actions-panel',
        styles.actionPanel
      )}
    >
      <Accordion data={data} />
    </div>
  );
};

ActionsPanel.propTypes = {
  gridApi: PropTypes.shape({
    exportDataAsCsv: PropTypes.func,
  }),
};

export default ActionsPanel;
