// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__actionPanel___iEzQp{margin:0 -10px;width:calc(100% + 20px) !important}.styles-module__actionPanel___iEzQp .styles-module__exportBtn___2Ht5B{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;padding:12px 15px;color:#fff}.styles-module__actionPanel___iEzQp .styles-module__exportBtn___2Ht5B img{height:25px;position:absolute;left:20px}.styles-module__actionPanel___iEzQp .styles-module__fileNameInput___2d4SA{height:40px;font-size:16px;background-color:#fff;border:1px solid #ddd}", "",{"version":3,"sources":["/app/app/javascript/components/DataGrid/GridToolbar/ActionsPanel/styles.module.scss"],"names":[],"mappings":"AAAA,oCACE,cAAA,CACA,kCAAA,CACA,sEACE,UAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,iBAAA,CAMA,UAAA,CALA,0EACE,WAAA,CACA,iBAAA,CACA,SAAA,CAIJ,0EACE,WAAA,CACA,cAAA,CACA,qBAAA,CACA,qBAAA","file":"styles.module.scss","sourcesContent":[".actionPanel {\n  margin: 0 -10px;\n  width: calc(100% + 20px) !important;\n  .exportBtn {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 12px 15px;\n    img {\n      height: 25px;\n      position: absolute;\n      left: 20px;\n    }\n    color: #fff;\n  }\n  .fileNameInput {\n    height: 40px;\n    font-size: 16px;\n    background-color: #fff;\n    border: 1px solid #ddd;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"actionPanel": "styles-module__actionPanel___iEzQp",
	"exportBtn": "styles-module__exportBtn___2Ht5B",
	"fileNameInput": "styles-module__fileNameInput___2d4SA"
};
module.exports = exports;
