import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Checkbox from './Checkbox';
import styles from './styles.module.scss';

const CheckboxContainer = ({ id, label, checked, onChange }) => {
  return (
    <div className={styles.formCheck}>
      <Checkbox
        id={id}
        checked={checked}
        onChange={onChange}
      />

      <label className={classNames('form-check-label', styles.label)} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

CheckboxContainer.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CheckboxContainer;
