import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'components/Checkbox/Checkbox';

const ColumnsPanel = ({
  columnSelection,
  columnApi,
  columnDefs,
  onColumnSelection,
}) => {
  let visibleColumns = columnApi
    ? columnApi.getDisplayedCenterColumns().reduce((prev, col) => {
        prev[col.colId] = col.visible;
        return prev;
      }, {})
    : {};
  visibleColumns = { ...visibleColumns, ...columnSelection };
  return (
    <ul className="grid-panel-content list-group">
      {columnDefs.map((item) => {
        return (
          <li
            className="list-group-item"
            key={`${item.field}-${visibleColumns[item.field]}`}
          >
            <Checkbox
              id={item.field}
              label={item.headerName}
              checked={visibleColumns[item.field] || false}
              onChange={onColumnSelection}
            />
          </li>
        );
      })}
    </ul>
  );
};

ColumnsPanel.propTypes = {
  columnApi: PropTypes.shape({
    getDisplayedCenterColumns: PropTypes.func,
  }),
  columnDefs: PropTypes.array,
  columnSelection: PropTypes.object,
  onColumnSelection: PropTypes.func,
}

export default ColumnsPanel;
