import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

const numberRegex = /^\d+$/;

// const decimalRegex = /[0-9]*[.,]?[0-9]+/;
export default function NumberEditor({ value, onCommit, onChange, className, cell }) {
  const input =  useRef();
  const { digits, type, decimal, signed = true } = cell;
  useEffect(() => {
    input.current.focus();
  }, []);

  const onInput = useCallback((evt) => {
    let newValue = evt.target.value;
    if (Number.isNaN(Number(newValue)) && newValue !== '') {
      newValue = value
    }
    if (type === 'decimal') {
      // If value contains decimal point and decimal parameter is defined then check for length of decimal part
      if (newValue.indexOf('.') > -1 && decimal) {
        if (value.slice(value.indexOf('.') + 1).length > decimal) {
          newValue = value;
        }
      }
    } else {
      if (!numberRegex.test(newValue) && newValue !== '') {
        newValue = value;
      }
      if (digits && newValue.length > digits) {
        newValue = value;
      }
    }
    if ((signed===false) && (Number(newValue)) < 0) {
      newValue = newValue.replace('-', '');
    }
    onChange(newValue);
  }, [decimal, digits, onChange, type, value]);

  return <input ref={input} type="text" pattern="[0-9]*[.,]?[0-9]+" className={`data-editor ${className}`} onChange={onInput} value={value} onKeyDown={e => {
    if (e.key === 'Tab') onCommit(value, e);
  }} />
}

NumberEditor.propTypes = {
  cell: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onCommit: PropTypes.func,
  value: PropTypes.string,
}
