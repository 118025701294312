import { useState, useEffect, useCallback } from 'react';

function useAvailableGridRowCount(gridApi) {
  const [availableRows, setAvailableRows] = useState(0);
  const [total, setTotal] = useState(0);
  const onPaginationChanged = useCallback(() => {
    const maxCount = gridApi.getInfiniteRowCount();
    const blocks = gridApi.getCacheBlockState();
    if(blocks && maxCount) {
      const maxPage = Math.max(...Object.keys(blocks));
      setTotal(maxCount);
      setAvailableRows(Math.min(maxCount, blocks[maxPage].endRow));
    } else {
      const maxCount = gridApi.getDisplayedRowCount();
      setTotal(maxCount);
      setAvailableRows(maxCount)
    }
  }, [gridApi]);

  useEffect(() => {
    if (!gridApi) return;
    gridApi.addEventListener('paginationChanged', onPaginationChanged);
    onPaginationChanged();
    return () => {
      gridApi.removeEventListener('paginationChanged', onPaginationChanged);
    };
  },  [gridApi, onPaginationChanged]);

  return { availableRows, total };
}

export default useAvailableGridRowCount;
