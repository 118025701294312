import React, { useState, useCallback, useEffect, useRef } from 'react';

import DataGrid from '../DataGrid';
import { dateCellRenderer } from 'utils';
import { DATE_FORMAT } from 'constants/index';
import Axios from 'axios';
import isEqual from 'lodash/isEqual';

/* global $ */

const ComplianceTable = () => {
  const [key, setKey] = useState(Date.now());
  const [status, setStatus] = useState({ checkStatus: true, pendingScans: {} })
  // const [checkStatus, setCheckStatus] = useState(true);
  // const [pendingScans, setPendingScans] = useState({});
  // const intervalId = useRef();
  const openPopup = useCallback(
    () => {
      $('.cis-container').removeClass('none')
    },
    [],
  );
  const closePopup = useCallback(
    () => {
      clearForm();
      $('.no-credentials').addClass('none');
      $('.no-valid-credentials').addClass('none');
      $('.cis-container').addClass('none');
    },
    [clearForm],
  );
  const clearForm = useCallback(
    () => {
      $('#access_key').val('');
      $('#secret_key').val('');
      $('#region_region').val('');
      $('.fa-circle-notch').addClass('none');
      $('#start-cis-scan').attr("disabled", false);
      $('#cancel-cis-request').attr("disabled", false);
    },
    [],
  );
  const checkScanStatus = useCallback(
    async () => {
      const { data } = await Axios.get('/compliance/check_for_incompleted_status_of_scans');
      const pending = data.data.filter(item => item.Scan_Status !== 'ERROR').filter(item => item.Scan_Status !== 'COMPLETED')
      return pending.reduce((prev, curr) => {
        prev[curr.Scan_Request_Id] = curr.Scan_Status;
        return prev;
      }, {});
    },
    [],
  );

  const poll = useCallback(async () => {
    if (status.checkStatus) {
      const pending = await checkScanStatus();
      const newStatus = { ...status };

      if (!isEqual(pending, status.pendingScans)) {
        newStatus.pendingScans = pending;
      }
      if (!isEqual(pending, status.pendingScans)) {
        console.log('Pending')
        setKey(Date.now()); // refresh Table to show updated status
      }
      newStatus.checkStatus = Object.keys(pending).length !== 0;
      if (!isEqual(newStatus, status)) {
        console.log('new status')
        setStatus(newStatus);
      }
    }

    // if (resp.Scan_Status !== scanStatus) {
    //   setKey(Date.now());
    //   setScanStatus(resp.Scan_Status);
    // }
    // if (resp.Scan_Status === 'Error' && resp.Scan_Status === 'Complete' ) {
    //   clearInterval(intervalId.current);
    //   intervalId.current = null;
    //   setScanStatus(null);
    // }
  }, [status]);
  useEffect(() => {
    // poll();
    const intervalId = setInterval(poll, 30000)
    return () => {
      clearInterval(intervalId);
    }
  }, [poll])
  useEffect(() => {
    // poll();
    // const intervalId = setInterval(poll, 30000)
    $('#cancel-cis-request').on('click', function () {
      closePopup();
    });
    function handler () {
      var access_key = $('input#access_key').val();
      var secret_key = $('input#secret_key').val();
      var region = $('#region_region').val();
      if (!access_key || !secret_key) {
        return;
      }
      $('#start-cis-scan').val('');
      $('.fa-circle-notch').removeClass('none');
      $('#start-cis-scan').attr('disabled', true);
      $('#cancel-cis-request').attr('disabled', true);
      $.ajax({
        url: '/compliance/start_cis_scan',
        data: {access_key: access_key, secret_key: secret_key, region: region},
        cache: false,
        method: 'POST',
        success: function(data){
          if (data['error'] == 'Invalid Credentials') {
            $('.no-valid-credentials').removeClass('none')
            $('.no-credentials').addClass('none')
            $('#start-cis-scan').attr('disabled', false)
            $('#cancel-cis-request').attr('disabled', false)
            $('.fa-circle-notch').addClass('none');
            $('#start-cis-scan').val('Request Scan')
              console.log(data)
          } else if (data['error'] == 'Blank Values Submitted'){
            $('.no-credentials').removeClass('none')
            $('.no-valid-credentials').addClass('none')
            $('#start-cis-scan').attr('disabled', false)
            $('#cancel-cis-request').attr('disabled', false)
            $('#start-cis-scan').val('Request Scan')
            console.log(data)
          } else {
            console.log(data)
            // $('#start-cis-scan').attr("disabled", false)
            // $('.cis-container').addClass('none')
            // location.reload();
            closePopup();
            setStatus(v => ({
              ...v,
              checkStatus: true,
            }))
            setKey(Date.now())
            clearForm();
          }
        },
        error: function(error){
          console.log(error)
        }
      });
    }
    $('#start-cis-scan').on('click', handler);
    return () => {
      // clearInterval(intervalId);
      $('#start-cis-scan').unbind('click', handler);
    }
  }, [])
  const columnDefs = [
    {
      field: 'Scan_Request_Id',
      sortable: true,
      headerName: 'Scan Id',
    },
    {
      field: 'Request_Creation_Timestamp',
      headerName: 'Created',
      sortable: true,
      cellRenderer: dateCellRenderer(DATE_FORMAT),
    },
    {
      field: 'Warning_Count',
      headerName: 'Warnings',
      type: ['textColumn'],
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        return `<div style="display:flex;"><span class="cis-warning-count">Warnings: ${data.Warning_Count}</span></div>`;
      },
    },
    {
      field: 'Critical_Count',
      headerName: 'Critical',
      type: ['textColumn'],
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        return `<div style="display:flex;"><span class="cis-critical-count"> Critical: ${data.Critical_Count}</span></div>`;
      },
    },
    {
      field: 'Scan_Status',
      headerName: 'Status',
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        if (data.Scan_Status == 'COMPLETED') {
          return `<div class="complete-scan">${data.Scan_Status}</div>`;
        } else if (data.Scan_Status == 'IN PROGRESS') {
          return `<div class="in-progress-scan">${data.Scan_Status}</div>`;
        } else if (data.Scan_Status == 'PENDING') {
          return `<div class="pending-scan">${data.Scan_Status}</div>`;
        } else if (data.Scan_Status == 'ERROR') {
          return `<div class="error-scan">${data.Scan_Status}</div>`;
        } else {
          return `<div>${data.Scan_Status}</div>`;
        }
      },
      type: ['textColumn'],
    },
    {
      field: '',
      headerName: 'Downloads',
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        if ((data.Scan_Status == 'IN PROGRESS') || (data.Scan_Status == 'PENDING') || (data.Scan_Status == 'ERROR')) {
          return ''
        } else {
          return ` <a title="Open PDF file." rel="" href="/compliance/compliance_report_pdf?scan_id=${data.Scan_Request_Id}" target="_blank"><i class="fas fa-file-pdf"></i></a> <a title="Download CSV file." rel="" href="/compliance/download_cis_csv?scan_id=${data.Scan_Request_Id}"><i class="fas fa-file-csv"></i></a> <a title="Download JSON file." class="compliance-links" rel="" href="/compliance/download_cis_json?scan_id=${data.Scan_Request_Id}"><i class="fas fa-file"></i></a>`;

        }
      },
      type: ['textColumn'],
    },
    {
      field: '',
      headerName: 'Links',
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        if ((data.Scan_Status == 'IN PROGRESS') || (data.Scan_Status == 'PENDING') || (data.Scan_Status == 'ERROR')) {
          return `<a style="visibility:hidden;" title="Open compliance reports page." rel="" href="/compliance/compliance_report?scan_id=${data.Scan_Request_Id}" target="_blank"><i class="fas fa-eye"></i></a> <a title="Delete compliance scan." onclick="return confirm('Are you sure you want to delete this scan?')" rel="" href="/compliance/delete_scan?scan_id=${data.Scan_Request_Id}" ><i class="fas fa-trash"></i></a>`;
        } else {
          return `<a title="Open compliance reports page." rel="" href="/compliance/compliance_report?scan_id=${data.Scan_Request_Id}" target="_blank"><i class="fas fa-eye"></i></a> <a title="Delete compliance scan." onclick="return confirm('Are you sure you want to delete this scan?')" rel="" href="/compliance/delete_scan?scan_id=${data.Scan_Request_Id}" ><i class="fas fa-trash"></i></a>`;
        }
      },
      type: ['textColumn'],
    },
  ];

  return (
    <div className="compliance-table-container">
      <button id="show-scan-modal" onClick={openPopup}>Start Scan</button>
      <br /><br />
      <h1>Previous Scans</h1>
      <DataGrid
        key={key}
        tableId="compliance_scans"
        columnDefs={columnDefs}
        apiUrl="/compliance/compliance_check_json"
        defaultSort={{ colId: 'Request_Creation_Timestamp', sort: 'asc' }}
      />

    </div>

  );
}

export default ComplianceTable;
