import React from 'react';
import PropTypes from 'prop-types';

function CellViewer({ value, row, col, className }) {
  return (
    <div
    className={className}
    id={`cell-${row}-${col}`}
  >
    {value}
  </div>
  )
}

CellViewer.propTypes = {
  className: PropTypes.string,
  col: PropTypes.number,
  row: PropTypes.number,
  value: PropTypes.any,
}


export default CellViewer;
