import React, { useCallback } from 'react'
import axios from 'axios';
import PropTypes from 'prop-types';
import Spreadsheet from 'components/Spreadsheet';

import styles from './styles.module.scss';

export default function StorageDevicesCustomFieldsSpreadsheet({ label, data, editable, columns, serverId, applicationId, assetType, headerColWidth, colWidth }) {

  const onSave = useCallback(
    async (change) => {
      const body = {
        value: change.value,
        application_id: change.cell.applicationId,
        server_id: change.cell.serverId,
        asset_type:change.cell.assetType,
        field: change.cell.field,
        data_type: change.cell.type,
      };
      await axios
      .get('/storage_devices/update_custom_attribute_from_cell', {
        params: { body },
      })
    },
    [],
  );
  const sheetColumns = [{
    name: 'Value',
    width: 'auto',
    key: 'name'
  }];
  return (
    <div className={styles.StorageDevicesCustomFieldsSpreadsheet}>
      <Spreadsheet label={label} data={data} editable={editable} columns={columns} sheetColumns={sheetColumns} onSave={onSave} cellAttributes={{ serverId, assetType }} headerColWidth={headerColWidth} colWidth={colWidth} style={{ maxHeight: '500px'}} />
    </div>
  )
}

StorageDevicesCustomFieldsSpreadsheet.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  editable: PropTypes.bool,
  serverId: PropTypes.number.isRequired,
  assetType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  headerColWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
StorageDevicesCustomFieldsSpreadsheet.defaultProps = {
  headerColWidth: 200,
  colWidth: 200,
};
