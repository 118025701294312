import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { TableContext } from 'utils';
import useGridSelectedRows from 'hooks/useGridSelectedRows';

/* global $ */

const DeleteRows = ({ gridApi }) => {
  const { rows: selectedRows } = useGridSelectedRows(gridApi);
  const { deleteInfo } = useContext(TableContext)
  const { deleteApiUrl, deleteIdKey, deleteWarning } = deleteInfo;

  const deleteRows = useCallback(async () => {
    confirmAlert({
      title: 'Confirm to submit',
      message: deleteWarning,
      buttons: [
        {
          label: 'No',
        },
        {
          label: 'Yes',
          className: 'btn btn-danger',
          onClick: () => {
            try {
              $.ajax({
                url: deleteApiUrl,
                data: { [deleteIdKey]: selectedRows.map((r) => r.id) },
                method: 'POST',
                cache: false,
                success: function () {},
              });
            } catch (error) {
              // eslint-disable-next-line
              console.log("Error", error);
            }
          },
        },

      ],
    });
  }, [deleteApiUrl, deleteIdKey, deleteWarning, selectedRows]);

  if (!gridApi) return null;
  return (
    <div className="deleteRows">
      <button
        className="btn btn-danger"
        disabled={selectedRows.length === 0}
        onClick={deleteRows}
      >
        Delete {selectedRows.length} Rows
      </button>
    </div>
  );
};

DeleteRows.propTypes = {
  gridApi: PropTypes.shape({
    addEventListener: PropTypes.func,
    getSelectedRows: PropTypes.func,
    removeEventListener: PropTypes.func,
  }),
};

export default DeleteRows;
