import React from 'react';
import PropTypes from 'prop-types';

function MultiValueViewer({ value }) {
  // console.log('BooleanViewer -> value', value, typeof value)
  // if (typeof value === 'boolean') {
  //   if (value) return <div color="primary">Yes</div>;
  //   else <div color="secondary">No</div>;
  // }
  let valueOptions = value;
  if (typeof value == 'string') {
    // valueOptions = value.split(',')
    return value;
  }
  // return <div color="secondary"></div>;
  return <div>{valueOptions.map(option => option.label).join(', ')}</div>;

}

MultiValueViewer.propTypes = {
  // value: PropTypes.oneOf([true, false, null, '']),
  value: PropTypes.any,
};

export default MultiValueViewer;
