import React from 'react';
import dayjs from 'dayjs';
import NumberEditor from 'components/Spreadsheet/NumberEditor';
import StrategyViewer from 'components/Spreadsheet/StrategyViewer';
import DateEditor from 'components/Spreadsheet/DateEditor';
import DateViewer from 'components/Spreadsheet/DateViewer';
import SelectEditor from 'components/Spreadsheet/SelectEditor';
import BooleanViewer from 'components/Spreadsheet/BooleanViewer';
import StrategySelectEditor from 'components/Spreadsheet/StrategySelectEditor';
import MultiSelectEditor from 'components/Spreadsheet/MultiSelectEditor';
import CellViewer from 'components/Spreadsheet/CellViewer';
import TextEditor from 'components/Spreadsheet/TextEditor';
import MultiValueViewer from 'components/Spreadsheet/MultiValueViewer';


/* eslint-disable-next-line max-len */
const IP_REGEX = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

const PHONE_REGEX = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/;

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const dateComparator = (selectedValue, cellValue) => {
  return dayjs(cellValue).diff(selectedValue, 'day');
};

export const checkboxSelection = (params) => {
  if (!params.data) return false;
  const displayedColumns = params.columnApi.getAllDisplayedColumns();
  return displayedColumns[0] === params.column;
};

export const durationCellRenderer = (params) => {
  if (!params.data) return '';
  const { value } = params;
  return value ? moment(value).fromNow() : 'NA';
};
export const dateCellRenderer = (format, fallbackText) => (params) => {
  if (!params.data) return '';
  const { value } = params;
  return value ? dayjs(value).format(format || 'YYYY-MM-DD HH:mm:ss') : fallbackText || 'NA';
};

export const redCountCellRenderer = className => (params) => {
  if (!params.data) return '';
  const { value } = params;
  return value === undefined || value === null || value === 0
    ? 0
    : `<span class="${className}">${value}</span>`;
};

export const TableContext = React.createContext({
  deleteInfo: null,
});

export const getEnumColumnParams = (enumMap) => {
  return {
    cellRenderer: (params) => {
      if (!params.data) return '';
      const { value } = params;
      return enumMap[value];
    },
    filterParams: {
      buttons: ['reset', 'apply'],
      closeOnApply: true,
      filterOptions: [
        'empty',
        ...Object.keys(enumMap).map(key => {
          return {
            displayKey: key,
            displayName: enumMap[key],
            test: function (filterValue, cellValue) {
              return cellValue === true;
            },
            hideFilterInput: true,
          };
        })
      ],
      suppressAndOrCondition: true,
    },
  };
};

const editors = {
  NumberEditor,
  DateEditor,
  SelectEditor,
  StrategySelectEditor,
  TextEditor,
  MultiSelectEditor
}

const viewers = {
  StrategyViewer,
  DateViewer,
  BooleanViewer,
  CellViewer,
  MultiValueViewer
}
const regexMap = {
  IP_REGEX,
  EMAIL_REGEX,
  PHONE_REGEX
}



const boolOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const strategyOptions = [
  { value: 'Rehost', label: 'Rehost' },
  { value: 'Refactor', label: 'Refactor' },
  { value: 'Replatform', label: 'Replatform' },
  { value: 'Repurchase', label: 'Repurchase' },
  { value: 'Retire', label: 'Retire' },
  { value: 'Retain', label: 'Retain' },
];

const regulatoryRequirements = [
  {value: 'PII', label: 'PII'},
  {value: 'SOX', label: 'SOX'},
  {value: 'HIPAA', label: 'HIPAA'},
  {value: 'Other', label: 'Other'},
  {value: 'None', label: 'None'}
]

// export const getSpreadsheetData = (data, cols, editable, cellAttributes = {}) => {
export const getSpreadsheetData = ({ data, columns, editable, cellAttributes = {}, headerColWidth = 200, colWidth = 200 }) => {
  const gridData = columns.map((col) => {
    const cells = data.reduce((prev, curr) => {
      const cell = { value: curr[col.field], readOnly: !editable, width: colWidth, application_id:curr.id, server_id: curr.id, header: col.headerName, ...col, ...cellAttributes };
      if (cell.dataEditor) {
        cell.dataEditor = editors[cell.dataEditor];
      }
      if (cell.valueViewer) {
        cell.valueViewer = viewers[cell.valueViewer];
      }
      if (cell.regex) {
        cell.regex = regexMap[cell.regex];
      }

      prev.push(cell);
      return prev;
    }, []);
    cells.unshift({ value: col.headerName, readOnly: true, width: headerColWidth });
    return cells;
  });
  return gridData;
};

export {boolOptions, strategyOptions, regulatoryRequirements, IP_REGEX, EMAIL_REGEX, PHONE_REGEX }
