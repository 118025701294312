// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__userTable___hdYrj{height:calc(100vh - 250px - 2.5rem)}.styles-module__userTable___hdYrj .ag-grid .ag-cell .cell-block-item{display:block;line-height:24px}.styles-module__userTable___hdYrj .ag-grid .ag-cell .ag-cell-wrapper{min-height:40px;height:auto}.styles-module__userTable___hdYrj .ag-grid .ag-cell .ag-cell-wrapper .status-actions button{height:24px;width:auto;padding:4.8px 16px;padding:.3rem 1rem;font-size:14.4px;font-size:.9rem;color:#fff}.styles-module__userTable___hdYrj .ag-grid .ag-cell .ag-cell-wrapper .status-actions button a{color:#fff}.styles-module__userTable___hdYrj .ag-grid .ag-cell .ag-cell-wrapper .status-actions button.approve-btn{background-color:#1565c0}.styles-module__userTable___hdYrj .ag-grid .ag-cell .ag-cell-wrapper .status-actions button.revoke-btn{background-color:#c00}", "",{"version":3,"sources":["/app/app/javascript/components/UsersTable/styles.module.scss"],"names":[],"mappings":"AAAA,kCACE,mCAAA,CAII,qEACE,aAAA,CACA,gBAAA,CAEF,qEACE,eAAA,CACA,WAAA,CAEE,4FACE,WAAA,CACA,UAAA,CACA,kBAAA,CAAA,kBAAA,CACA,gBAAA,CAAA,eAAA,CACA,UAAA,CACA,8FACE,UAAA,CAEF,wGACE,wBAAA,CAEF,uGACE,qBAAA","file":"styles.module.scss","sourcesContent":[".userTable {\n  height: calc(100vh - 250px - 2.5rem);\n  :global .ag-grid {\n    \n    .ag-cell {\n      .cell-block-item {\n        display: block;\n        line-height: 24px;\n      }\n      .ag-cell-wrapper {\n        min-height: 40px;\n        height: auto;\n        .status-actions {\n          button {\n            height: 24px;\n            width: auto;\n            padding: 0.3rem 1rem;\n            font-size: 0.9rem;\n            color: #fff;\n            a {\n              color: #fff;\n            }\n            &.approve-btn {\n              background-color: #1565c0;\n            }\n            &.revoke-btn {\n              background-color: #cc0000;\n            }\n          }\n        }\n        \n      }\n    }\n  }\n}"]}]);
// Exports
exports.locals = {
	"userTable": "styles-module__userTable___hdYrj"
};
module.exports = exports;
