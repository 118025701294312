import React from 'react'
import PropTypes from 'prop-types';

export default function SheetRenderer({ label, className, columns, children, headerColWidth, style }) {

  return (
    <div className="table-container" style={style}>
    <table
      className={className}
    >
      <thead>
        <tr>
          <th style={{whiteSpace: 'nowrap', width: headerColWidth}} className="data-grid-header-cell read-only row-handle" key="$$actionCell">{label}</th>
          {columns.map((col) => (
            <th key={col.key} id={col.key} className="data-grid-header-cell" style={{ width: col.width }} title={col.name}>
              {col.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </table>
    </div>
  )
}

SheetRenderer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  label: PropTypes.string,
  headerColWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  columns: PropTypes.array,
  style: PropTypes.object,
};
SheetRenderer.defaultProps = {
  label: 'Attributes',
  headerColWidth: 200,
  style: {},
};

