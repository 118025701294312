import React from 'react';
import PropTypes from 'prop-types';
import useAvailableGridRowCount from 'hooks/useAvailableGridRowCount';

export default function GridFooter({ gridApi }) {
  const { availableRows, total } = useAvailableGridRowCount(gridApi);
  return (
    <div className="table-footer">
      <span>
        {availableRows} of {total} Rows
      </span>
    </div>
  );
}

GridFooter.propTypes = {
  gridApi: PropTypes.object,
}
