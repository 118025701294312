import React, { memo, useCallback, useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function GridToolbar({ panels, gridApi }) {
  const panelRef = useRef();
  const [filterModel, setFilterModel] = useState(null);
  const [rowSelection, setRowSelection] = useState([]);
  const actionRefs = panels.reduce((prev, p) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    prev[p.name] = useRef();
    return prev;
  }, {});
  const [panelIndex, setPanelIndex] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen((v) => !v);
  }, []);

  const resetSelection = useCallback(() => {
    gridApi.deselectAll();
  }, [gridApi]);

  const resetAllFilters = useCallback(() => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged();
  }, [gridApi]);

  const onFilterChnaged = useCallback(
    () => {
      if (!gridApi) return;
      const filterModel = gridApi.getFilterModel();
      setFilterModel(Object.keys(filterModel).length > 0);
    },
    [gridApi]
  );

  const onSelectionChnaged = useCallback(
    () => {
      if (!gridApi) return;
      const selectedRows = gridApi.getSelectedRows();
      setRowSelection(selectedRows);
    },
    [gridApi]
  );
  useEffect(() => {
    if (!gridApi) return;

    gridApi.addEventListener('filterChanged', onFilterChnaged);
    gridApi.addEventListener('selectionChanged', onSelectionChnaged);
    return () => {
      gridApi.removeEventListener('filterChanged', onFilterChnaged);
      gridApi.removeEventListener('selectionChanged', onSelectionChnaged);
    };
  }, [gridApi, onFilterChnaged, onSelectionChnaged]);

  const updatePanel = useCallback(
    (index) => {
      if (panelIndex === index || panelIndex === undefined) {
        toggleOpen();
      }
      setPanelIndex(index);
    },
    [panelIndex, toggleOpen]
  );

  const selectedPanel = panels[panelIndex];

  return (
    <div className={styles['ag-grid-toolbar-container']}>
      <div className="ag-grid-toolbar-wrapper">
        <div className="ag-grid-toolbar">
          <span
            className={classNames('toolbar-panel-trigger', { ['disable-action']: !filterModel })}
            onClick={resetAllFilters}
          >
            <i className="fa fa-filter" />
            Reset Filters
          </span>
          <span
            className={classNames('toolbar-panel-trigger', { ['disable-action']: rowSelection.length === 0 })}
            onClick={resetSelection}
          >
            <i className="fa fa-check-square" />
            Reset Selection
          </span>
          {panels.map((panel, index) => (
            <span
              key={panel.name}
              ref={actionRefs[panel.name]}
              onClick={() => updatePanel(index)}
              className={classNames('toolbar-panel-trigger')}
            >
              <i className={`fa ${panel.icon}`} />
              {panel.name}
            </span>
          ))}
        </div>
      </div>
      <div
        id="columns-panel"
        className={`sidenav ${isOpen ? 'open' : 'closed'}`}
        ref={panelRef}
      >
        <div className="panel-container">
          
          {selectedPanel && (
            <>
              <div className="panel-header">
                <i className="fa fa-times closebtn" onClick={toggleOpen} />
                <span className="panel-title">
                  <i className={`fa ${selectedPanel.icon}`} />
                  {selectedPanel.name}
                </span>
              </div>
              
              {selectedPanel.content}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

GridToolbar.propTypes = {
  gridApi: PropTypes.shape({
    addEventListener: PropTypes.func,
    deselectAll: PropTypes.func,
    getFilterModel: PropTypes.func,
    getSelectedRows: PropTypes.func,
    onFilterChanged: PropTypes.func,
    removeEventListener: PropTypes.func,
    setFilterModel: PropTypes.func,
  }),
  panels: PropTypes.array,
};

export default memo(GridToolbar);
