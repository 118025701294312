import React, { useCallback } from 'react'
import axios from 'axios';
import PropTypes from 'prop-types';
import Spreadsheet from 'components/Spreadsheet';

import styles from './styles.module.scss';

export default function ApplicationEnvironmentSpreadsheet({ data, editable, columns, applicationId, headerColWidth, colWidth }) {

  const onSave = useCallback(
    async (change) => {
      const body = {
        value: change.value,
        application_id: change.cell.applicationId,
        field: change.cell.field,
        data_type: change.cell.type,
      };
      await axios
      .get('/application_environments/update_stack_attribute_from_cell', {
        params: { body },
      })
    },
    [],
  );
  const sheetColumns = data.map((item) => ({
    name: item.hostname,
    key: item.id,
  }));
  return (
    <div className={styles.applicationSpreadsheet}>
      <Spreadsheet data={data} editable={editable} columns={columns} sheetColumns={sheetColumns} onSave={onSave} cellAttributes={{ applicationId }} headerColWidth={headerColWidth} colWidth={colWidth} />
    </div>
  )
}

ApplicationEnvironmentSpreadsheet.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  editable: PropTypes.bool,
  applicationId: PropTypes.number.isRequired,
  headerColWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
ApplicationEnvironmentSpreadsheet.defaultProps = {
  headerColWidth: 200,
  colWidth: 200,
};
