import React from 'react';
import PropTypes from 'prop-types';

function BooleanViewer({ value }) {
  // console.log('BooleanViewer -> value', value, typeof value)
  // if (typeof value === 'boolean') {
  //   if (value) return <div color="primary">Yes</div>;
  //   else <div color="secondary">No</div>;
  // }

  // return <div color="secondary"></div>;
  return <div>{value ? 'Yes' : 'No'}</div>;
}

BooleanViewer.propTypes = {
  // value: PropTypes.oneOf([true, false, null, '']),
  value: PropTypes.any,
};

export default BooleanViewer;
