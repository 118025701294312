import PropTypes from 'prop-types';
import React, { useRef } from 'react';

const Checkbox = ({ id, label, checked, onChange }) => {
  const ref = useRef();
  return (
    <>
      <input
        type="checkbox"
        className="form-check-input"
        id={id}
        ref={ref}
        onChange={() => {
          onChange(id, ref.current.checked);
        }}
        checked={checked}
      />
      {label}
    </>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default Checkbox;