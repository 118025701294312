import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function DateViewer({ value }) {
  return <div>{value ? moment(value).format('YYYY-MM-DD') : ''}</div>;
}

DateViewer.propTypes = {
  value: PropTypes.string,
};

export default DateViewer;
