import React from 'react';
import PropTypes from 'prop-types';

function StrategyViewer({ value }) {
  
  return <div>{value}</div>;
}

StrategyViewer.propTypes = {
  // value: PropTypes.oneOf([true, false, null, '']),
  value: PropTypes.any,
};

export default StrategyViewer;
