import React from 'react';
import capitalize from 'lodash/capitalize';

import DataGrid from 'components/DataGrid';
import { dateCellRenderer } from 'utils';
import styles from './styles.module.scss';
import { DATE_FORMAT } from 'constants/index';

const UsersTable = () => {
  const columnDefs = [
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 250,
      pinned: 'left',
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data, context } = params;
        if (context && context.can_send_invite) {
          return `<a href="/users/${data.id}">${data.email}</a>`;
        } else {
          return data.email;
        }
      },
    },
    {
      field: 'clients',
      headerName: 'Client',
      minWidth: 250,
      valueGetter: (params) => {
        if (!params.data) return '';
        const { data } = params;
        return data.clients.map((client) => client.name).join(',');
      },
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        suppressAndOrCondition: true,
        filterOptions: ['contains'],
      },
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { value } = params;
        return value
          .split(',')
          .map((client) => {
            return `
          <span class="cell-block-item">
              <i class="fas fa-check"></i>
              ${client}
          </span>`;
          })
          .join('');
      },
    },
    {
      field: 'roles',
      headerName: 'Roles',
      minWidth: 250,
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        suppressAndOrCondition: true,
        filterOptions: ['contains'],
      },
      valueGetter: (params) => {
        if (!params.data) return '';
        const { data } = params;
        return data.roles.map((role) => role.name).join(',');
      },
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { value } = params;
        return value
          .split(',')
          .map((role) => {
            return `
          <span class="cell-block-item">
              <i class="fas fa-check"></i>
              ${capitalize(role)}
          </span>`;
          })
          .join('');
      },
    },
    {
      field: 'last_sign_in_at',
      headerName: 'Last Sign In',
      type: ['dateColumn'],
      cellRenderer: dateCellRenderer(
        'DD MMM YYYY HH:mm a',
        'No Record of Login'
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { clients, email } = params.data;
        const location = window.location.origin;
        if (!clients || clients.length === 0) {
          // return '';
          const disabled = 'disabled';
          return `
        <span class="status-actions">
          <button class="approve-btn role-button" ${disabled}>
            Resend Invite
          </button>
        </span>`;
        } else {
          const url = `client_id=${clients[0].id}&email=${email}`;

          return `
        <span class="status-actions">
          <button class="approve-btn role-button">
            <a rel="nofollow" data-method="post" href="${location}/admin/resend_invite?${url}">
              Resend Invite
            </a>
          </button>
        </span>`;
        }
      },
      filter: false,
      sortable: false,
      pinned: 'right',
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      hide: true,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      hide: true,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      type: ['dateColumn'],
      cellRenderer: dateCellRenderer(DATE_FORMAT),
      hide: true,
    },
    {
      field: 'confirmed_at',
      headerName: 'Confirmed At',
      type: ['dateColumn'],
      cellRenderer: dateCellRenderer(DATE_FORMAT),
      hide: true,
    },
    {
      field: 'last_sign_in_ip',
      headerName: 'Last Login IP',
      hide: true,
    },
    {
      field: 'sign_in_count',
      headerName: 'Last Login IP',
      type: ['numberColumn'],
      hide: true,
    },
    {
      field: 'password_updated_at',
      headerName: 'Password Updated At',
      type: ['dateColumn'],
      cellRenderer: dateCellRenderer(DATE_FORMAT),
      hide: true,
    },
  ];

  return (
    <DataGrid
      className={styles.userTable}
      rowModelType="clientSide"
      tableId="admin_users"
      columnDefs={columnDefs}
      apiUrl="/users/get_users_json"
      defaultSort={{ colId: 'last_sign_in_at', sort: 'desc' }}
      defaultColDef={{
        cellStyle: { display: 'flex', 'align-items': 'flex-start' },
      }}
    />
  );
};

export default UsersTable;
