import React from 'react'
import PropTypes from 'prop-types';

export default function RowRenderer({ className, children }) {
  return (
    <tr className={className}>
      <td className="cell read-only row-handle" key="$$actionCell" />
      {children}
    </tr>
  )
}

RowRenderer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}
