import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class AccordionItem extends React.Component {
  render() {
    const {
      props: { content, title, opened, onClick, index },
    } = this;

    return (
      <div
        className={classnames('accordion-item', { ['accordion-item--opened']: opened })}
      >
        <div className="accordion-item__line" >
          <span className="accordion-item__icon" onClick={() => onClick(index)} />
          <span className="accordion-item__title" >{title}</span>
        </div>
        <div className="accordion-item__inner">
          <div className="accordion-item__content">
            {content}
          </div>
        </div>
      </div>
    );
  }
}

AccordionItem.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  opened: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
}

export default AccordionItem;
