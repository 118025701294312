import { useState, useEffect, useCallback } from 'react';

function useGridSelectedRows(gridApi) {
  const [selectedRows, setSelectedRows] = useState([]);
  const onSelectionChnaged = useCallback(() => {
    const selectedRows = gridApi.getSelectedRows();
    setSelectedRows(selectedRows);
  }, [gridApi]);
  
  useEffect(() => {
    gridApi.addEventListener('selectionChanged', onSelectionChnaged);
    onSelectionChnaged(); // run when rendered first time to show previus selections
    return () => {
      gridApi.removeEventListener('selectionChanged', onSelectionChnaged);
    };
  },  [gridApi, onSelectionChnaged]);

  return { rows: selectedRows, count: selectedRows.length };
}

export default useGridSelectedRows;
