import React from 'react'
import PropTypes from 'prop-types';

export default function StrategySelectEditor({ cell, value, onCommit }) {
  return (
    <select className="form-control" value={value} onChange={e => {
      onCommit(e.target.value, e);
    }}>
      
      {cell.options.map(op => <option key={op.value} value={op.value}>{op.label}</option>)}
    </select>
  );
}

StrategySelectEditor.propTypes = {
  cell: PropTypes.shape({
    options: PropTypes.array,
  }),
  onChange: PropTypes.func,
  onCommit: PropTypes.func,
  value: PropTypes.any,
}
