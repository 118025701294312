import React from 'react';

import DataGrid from '../DataGrid';

const StacksTable = () => {
  const columnDefs = [
    {
      field: 'application_name',
      headerName: 'Application Name',
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        return `<a rel="" href="/client_applications/${data.client_application_id}">${data.application_name}</a>`;
      },
      type: ['textColumn'],
    },
    {
      field: 'name',
      headerName: 'Environment',
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        return `<a rel="" href="/application_environments/${data.id}">${data.name}</a>`;
      },
      type: ['textColumn'],
    },
    {
      field: '',
      headerName: 'Grid View',
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        return `<a rel="" href="/application_environments/${data.id}/stack_table">Link</a>`;
      },
      type: ['textColumn'],
    },

    {
      field: 'servers_json',
      headerName: 'Servers',
      sortable: false,
      valueGetter: function(params) {
        if (!params.data) return '';
        const { data } = params;
        const servers = Object.values(JSON.parse(data.servers_json));
        return servers.map(server => `${server.hostname} - ${server.host_ip}`).join('');
      },
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        const servers = Object.values(JSON.parse(data.servers_json));
        const serverLinks = servers
          .map(
            (server) =>
              `
              <a
                style="display: block;line-height: 25px;"
                rel=""
                href="/client_servers/${server.id}">
                  ${server.hostname} - ${server.host_ip}
              </a>
            `
          )
          .join('');
        return `<div style="margin: 5px 0;">
            ${serverLinks}
        </div>
        `;
      },
    },
  ];

  return (
    <DataGrid
      tableId="application_stacks"
      columnDefs={columnDefs}
      apiUrl="/application_environments/get_stacks_json"
      rowModelType="clientSide"
    />
  );
};

export default StacksTable;
