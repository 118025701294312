import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import DataGrid from 'components/DataGrid';
import styles from './styles.module.scss';
/* global $ */

const PricingServersTable = () => {
  const [pricingType, setPricingType] = useState(0);
  const [gridRef, setGridRef] = useState(React.createRef());

  // const gridRef = useRef();

  const updateData = useCallback((type) => {
    setPricingType(type);
    setGridRef(React.createRef());
  }, []);
  const getParams = useCallback(()=>{
    return `pricing=${pricingType}`
  }, [pricingType]);

  useEffect(() => {
    if (gridRef.current && gridRef.current.gridApi) {
      setTimeout(() => gridRef.current.gridApi.refreshInfiniteCache(), 100);
    }
  }, [pricingType])
  const columnDefs = [
    {
      field: 'hostname',
      headerName: 'Hostname',
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        return `<a rel="" href="/client_servers/${data.id}">${data.hostname}</a>`;
      },
      type: ['textColumn'],
    },
    {
      field: 'host_ip',
      headerName: 'Host IP',
      sortable: true,
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        return `<a rel="" href="/client_servers/${data.id}">${data.host_ip}</a>`;
      },
      filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        suppressAndOrCondition: true,
      },
    },
    {
      field: 'application_name',
      headerName: 'Applications',
    },
    {
      field: 'instance_type',
      headerName: 'Instance Type',
    },
    {
      field: 'os_type',
      headerName: 'Operating System',
    },
    {
      field: 'pricing_info',
      headerName: 'Annual Cost',
      sortable: false,
      filter: false,
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        if (!data.pricing_info) return `<div style="margin: 5px 0;" id="server-cost-${data.id}">
          Not Yet Calculated
        </div>`;
        if (JSON.parse(data.pricing_info)['error']) return `<div style="margin: 5px 0;color:red">
          ${(JSON.parse(data.pricing_info)['error'])}
        </div>`;

        const pricingData =
          '$' +
          (
            Math.round(JSON.parse(data.pricing_info)['cost_per_year'] * 100) /
            100
          ).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          ' ' +
          JSON.parse(data.pricing_info)['currency'];
        return `<div style="margin: 5px 0;" id="server-cost-${data.id}">
            ${pricingData}
        </div>
        `;
      },
    },
    {
      field: 'pricing_info2',
      headerName: 'Priced At',
      sortable: false,
      filter: false,
      cellRenderer: (params) => {
        if (!params.data) return '';
        const { data } = params;
        if (!data.pricing_info) return `<div style="margin: 5px 0;">

        </div>`;
        if (JSON.parse(data.pricing_info)['error']) return `<div style="margin: 5px 0;color:red">
          
        </div>`;
        const pricingTimestamp =
          (
            (JSON.parse(data.pricing_info)['date_calculated'])
          )
        return `<div style="margin: 5px 0;">
            ${pricingTimestamp}
        </div>
        `;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 200,
      cellRenderer: 'actionCell',
      filter: false,
      sortable: false,
      pinned: 'right',
    },
  ];

  return (
    <div className={styles.pricingServersTable}>
      <div className={`pricing-table-tab ${pricingType === 0 ? 'active': ''}`} onClick={()=>updateData(0)}>Calculated & Ready Servers</div>
      <div className={`pricing-table-tab ${pricingType === 2 ? 'active': ''}`} onClick={()=>updateData(2)}>Not Ready Servers </div>
      <DataGrid
        key={`servers-${pricingType}`}
        tableId="client_servers"
        columnDefs={columnDefs}
        apiUrl="/discovery/pricing/servers_pricing_json"
        getParams={getParams}
        frameworkComponents={{
          actionCell: Actions,
        }}
        gridRef={gridRef}
        panels={["Columns"]}
      />
      </div>
  );
};

export default PricingServersTable;

const Actions = ({ data }) => {
  const [loading, setLoading] = useState(false);
  if (!data) return null;
  let disabled = false;
  const calculateCost = async (id) => {
    setLoading(true);
    const url = '/discovery/pricing/calculate_server_price';

    $.ajax({
        url: url,
        data: {server_id: id},
        cache: false,
        method: 'POST',
        success: function(text){
           setLoading(false);
           $('#server-cost-'+id).empty();
           $('#server-cost-'+id).html(text);   
           $('#server-cost-'+id).css({'background-color':'#4e74ba'}).animate({'background-color':'#FFFFFF'}, 2000);   
           console.log(id)
           console.log(text)    
        }
    });
  };

  const location = window.location.origin;

  if (data.instance_type == null) {
    disabled = true;
  }
  return (
        <a
          data-method="get"
          href={`${location}/client_servers/${data.id}/pricing`}
        >
          View Pricing Options
        </a>
  );
};
Actions.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.any,
    instance_type: PropTypes.any,
  }),
};
