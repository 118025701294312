import React from 'react'
import PropTypes from 'prop-types';

export default function Tabs({ activeTab, setActiveTab, tabs }) {
  const activeClass = 'application-owner-tab-active';
  return (
    <div className="application-owner-buttons-container">
      {tabs.map(tab => (
        <div
          key={tab.value}
          className={`application-owner-tab ${activeTab === tab.value ? activeClass : ''}`}
          id={`${tab.value}-tab`}
          data-param={tab.value}
          onClick={() => setActiveTab(tab.value)}
        >
          {tab.label}
        </div>
      ))}
        {/* <div className="application-owner-tab <%="application-owner-tab-active" if @application_owner && @pending_review.blank?%>" id="application-owner-tab" data-param="owner">Your Servers</div>
        <div className="application-owner-tab <%="application-owner-tab-active" if @application_owner && @pending_review%>" id="application-owner-tab" data-param="owner">Pending Review</div> */}
    </div>
  )
}

Tabs.propTypes = {
  activeTab: PropTypes.any,
  setActiveTab: PropTypes.any,
  tabs: PropTypes.array,
}
